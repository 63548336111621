import axios from 'axios'
const isProd = process.env.NODE_ENV === 'production'
export const APIHOST = isProd ? 'https://fc-resource-node-api.krzb.net' : 'http://localhost:20030'

export const request = async ({ method = 'GET', data, url, params = {}, headers = {}, isPrefix = true }) => {
  try {
    const res = await axios({
      method,
      url: isPrefix ? `${APIHOST}${url}` : url,
      data,
      timeout: 20 * 1000,
      params: {
        t: new Date().getTime(),
        version: 'v2',
        ...params
      },
      headers: {
        ...headers
      },
      withCredentials: isPrefix ? true : false,
    })
    return res.data
  } catch (err) {
    console.log(`request error: ${err}`)
    return null
  }
}
