import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
import store from "../store/index";
import { getUserinfo } from "../api/user";

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/redirect/:id",
    name: "Redirect",
    component: () => import("@/views/Redirect.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "detail-1/:id",
        name: "detail-1",
        component: () => import("@/views/Detail1/Index.vue"),
      },
      {
        path: "detail-2/:id",
        name: "detail-2",
        component: () => import("@/views/Detail2/Index.vue"),
      },
      {
        path: "detail-3/:id",
        name: "detail-3",
        component: () => import("@/views/Detail3/Index.vue"),
      },
      {
        path: "detail-4/:id",
        name: "detail-4",
        component: () => import("@/views/Detail4/Index.vue"),
      },
      {
        path: "copyright",
        name: "copyright",
        component: () => import("@/views/Copyright.vue"),
      },
      {
        path: "about/agree",
        name: "agree",
        component: () => import("@/views/about/agree.vue"),
      },
      {
        path: "about/policy",
        name: "policy",
        component: () => import("@/views/about/policy.vue"),
      },
      {
        path: "about/safe",
        name: "safe",
        component: () => import("@/views/about/safe.vue"),
      },
      {
        path: "about/version",
        name: "version",
        component: () => import("@/views/about/version.vue"),
      },
    ],
  },
  {
    path: "/:w+",
    name: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const checkIfLogin = async () => {
  if (store.state.userinfo)
    return { isLogin: true, isVip: !store.state.userinfo.expired };
  try {
    const res = await getUserinfo();
    if (res && res.code == 0) {
      store.commit("setLogin", true);
      store.commit("setUserinfo", res.data);
      return { isLogin: true, isVip: !res.data.expired };
    } else if (res && res.code == 1001) {
      store.commit("setLogin", false);
      localStorage.setItem("token", "");
      return { isLogin: false };
    }
    return { isLogin: false };
  } catch (err) {
    return { isLogin: false };
  }
};

router.beforeEach((to, from, next) => {
  // 页面兼容
  if (location.pathname != "/")
    location.href = "/#/main" + location.pathname + location.search;

  if (window._hmt) {
    // pv 打点
    if (to.path) {
      window._hmt.push([window.location.pathname + "#" + to.fullPath]);
    }
  }

  checkIfLogin().then(({ isLogin }) => {
    if (to.meta.requiredAuth) {
      if (!isLogin) {
        return next({ name: "login" });
      }
      next();
    } else {
      next();
    }
  });
});
export default router;
