import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    userinfo: null,
    payDialogShow: false,
    loginDialogShow: false,
    loginEmailDialogShow: false,
    bindUserDialog: false,
  },
  mutations: {
    setLogin(state, data) {
      state.isLogin = data;
    },
    setUserinfo(state, data) {
      state.userinfo = data;
    },
    setPayDialog(state, data) {
      state.payDialogShow = data;
    },
    setLoginDialog(state, data) {
      state.loginDialogShow = data;
    },
    setLoginEmailDialog(state, data) {
      state.loginEmailDialogShow = data;
    },
    setBindUserDialog(state, data) {
      state.bindUserDialog = data;
    },
  },
  actions: {},
  modules: {},
});
