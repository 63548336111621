export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent)
}

export const isWeixin = () => { 
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export const isIOS = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

export const isAndroid = () => {
  return /(Android)/i.test(navigator.userAgent)
}