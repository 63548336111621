import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import { APIHOST } from "./api/_base";
import { getUserinfo } from "./api/user";
import { PROJECT_NAME, DOMAIN } from "./constants";
import { isWeixin, isMobile, isIOS, isAndroid } from "@/utils/navigator";
Vue.mixin({
  data() {
    return {
      DOMAIN,
      APIHOST,
      PROJECT_NAME,
      isIOS: isIOS(),
      isWeixin: isWeixin(),
      isMobile: isMobile(),
      isAndroid: isAndroid(),
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "userinfo",
      "payDialogShow",
      "loginDialogShow",
      "loginEmailDialogShow",
      "bindUserDialog",
    ]),
    testing() {
      return ["localhost", "127.0.0.1", "test."].some(
        (x) => location.host.indexOf(x) > -1
      );
    },
  },
  methods: {
    openWindow(url, target = false) {
      const obj = document.createElement("a");
      if (target) {
        obj.target = "_blank";
      }
      obj.referrerPolicy = "origin";
      obj.href = url;
      obj.click();
    },
    async updateUserinfo() {
      const res = await getUserinfo();
      if (res && res.code == 0) {
        this.$store.commit("setLogin", true);
        this.$store.commit("setUserinfo", res.data);
      } else if (res && res.code == 1001) {
        this.$store.commit("setLogin", false);
        localStorage.setItem("token", "");
      }
    },
    showPayDialog() {
      this.$store.commit("setPayDialog", true);
    },
    hidePayDialog() {
      this.$store.commit("setPayDialog", false);
    },
    showLoginDialog() {
      this.$store.commit("setLoginEmailDialog", true);
    },
    hideLoginDialog() {
      this.$store.commit("setLoginEmailDialog", false);
    },
    showBindUserDialog() {
      this.$store.commit("setBindUserDialog", true);
    },
    hideBindUserDialog() {
      this.$store.commit("setBindUserDialog", false);
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    formatTime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    bytesToSize(bytes) {
      if (bytes < 0) {
        return "0KB";
      }
      const b = parseInt(bytes, 10);
      const sizes = ["B", "KB", "MB", "GB", "TB"];
      if (isNaN(b)) {
        return "0KB";
      }
      if (b === 0) {
        return "0KB";
      }
      const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10);
      if (i === 0) {
        return `${b}${sizes[i]}`;
      }
      if (i > 1) {
        return `${(b / 1024 ** i).toFixed(1)}${sizes[i]}`;
      } else {
        return `${(b / 1024 ** i).toFixed(0)}${sizes[i]}`;
      }
    },
  },
});
