<template>
  <div id="app">
    <router-view :key="$router.currentRoute.fullPath" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_base.scss";
#app {
  min-height: 100vh;
}
</style>
